import React from "react";

export interface TextProps {
  description?: string;
  headingType?: "h1" | "h2" | "h3" | "h4"; 
  mainTitle?: string;
  color?: string;
}

export const TextContainer: React.FC<TextProps> = ({
  description,
  headingType="h1",
  mainTitle,
  color, // Default color
}) => {
  // Dynamically create the heading element
  const Heading = React.createElement(
    headingType,
    { className: "sercivestext-title", style: { color } },
    mainTitle
  );

  return (
    <div className="servicesText">
      {Heading}
      {description && <p className="sercivestext-Des">{description}</p>}
    </div>
  );
};
