import React from 'react';
import { TextContainer, TextProps } from '../CONTAINERTEXT/ContainerText';
import './services.css';
interface ServicesItems {
  imagePath?: string;
  title?: string;
  description?: string;

}
interface ServicesDetailData {
  backgroundColor?:string
}
const servicesItems = [
  {
    imagePath: './webdesign1.gif',
    title: 'Digitale Branding Dienstleistungen',
    description: 'Bei Buhshitech entwickeln wir maßgeschneiderte Strategien, um Ihre digitale Präsenz zu stärken. Von Webdesign und Social Media bis hin zu Content-Marketing und SEO schaffen wir ein einheitliches Branding, das Ihre Marke sichtbar macht, Kundenbindung stärkt und sich im digitalen Raum abhebt. Lassen Sie Ihre Marke online erfolgreich sein.'
  },
  {
    imagePath: './printer.gif',
    title: 'Digitalisierung ',
    description: 'Digitalization is the key to efficiency and competitiveness. At Buhshitech, we help businesses optimize processes, implement digital solutions, and seize new opportunities. From automation to digital transformation, we support you in achieving your business goals and thriving in the digital age.'
  },
  
];
const textDetail:TextProps = {
  mainTitle:"Digitales Marketing, SEO, Google Ads, Webentwicklung, OCR & Dokumentenmanagement in Wien",
  description:"Buhshitech: Ihre digitale Agentur in Wien. Experten für SEO, Google Ads, Webentwicklung und Dokumentenmanagement. Steigern Sie Ihre Online-Sichtbarkeit und erreichen Sie Erfolg mit maßgeschneiderten Lösungen!",
  headingType:"h2"
}

const mapServicesData = servicesItems.map((item:ServicesItems,index) => {
  return (
    <React.Fragment key={item.imagePath}>
      <div  className="services__box">
        <figure className="services__icon">
          <img src={item.imagePath} alt="" />
        </figure>
        <div className="services__content">
          <h2 className="services__title">{item.title}</h2>
          <p className="services__description">{item.description}</p>
        </div>
      </div>
    </React.Fragment>
  );
});

export const SubServices: React.FC<ServicesDetailData> = (props) => {
  return (
    <>
      <TextContainer  {...textDetail}/>
      <section className="services" style={{backgroundColor:props.backgroundColor}}>{mapServicesData}</section>
    </>
  );
};
