import React from "react";
import { AnimationLotti } from "../../SUPPORT/COMPONENT/ANIMATIONS/SERVICES/Services";
import { HeroImage } from "../SubComponents/HEROIMAGE/HeroImage";
import data from "./home.json";
import { SubServices } from "../SubComponents/SubServices/SubServises";
import { WebPlanning } from "../SubComponents/WEBPLANNING/WebPlanning";
import { TextContainer } from "../SubComponents/CONTAINERTEXT/ContainerText";
import { JamboImageContainer, JamoImageContentProps } from "../SubComponents/JAMBOIMABGE/JamboImageContainer";

interface HomeProps {
  animationVisible: boolean;
}
export const Home: React.FC<HomeProps> = (props) => {
  const Home :JamoImageContentProps= {
    title: "Digitales Marketing, SEO & Webentwicklung in Wien",
    desc: "Buhshitech: Ihre digitale Agentur in Wien. Experten für SEO, Google Ads, Webentwicklung und Dokumentenmanagement. Steigern Sie Ihre Online-Sichtbarkeit und erreichen Sie Erfolg mit maßgeschneiderten Lösungen!",
    isImageVisibel: false,
    mediaPositionLeft: false,
    isButtonVisible: false,
    buttonText: "OUR PROJECT",
    titleHeading: "h1",
    animationVisible: props.animationVisible,
    homeContactAnimationData: data,
    animationLoop: true,
    containerWidth: "100%",
     backgroundColor:"#226d89",
     h1Color:"#ffff",
     pColor:"#ffff"

  
  };
  return (
    <>

      <JamboImageContainer
        animationVisible={props.animationVisible}
        {...Home}
      />
      <SubServices />
      <div className="plannning-container">
        <WebPlanning />
      </div>
    
    </>
  );
};
