import React from 'react';
import { TextContainer, TextProps } from '../CONTAINERTEXT/ContainerText';
import './planning.css';
interface PlanningStep {
  id: string;
  title: string;
  description: string;
}
const PlanningData:PlanningStep[] = [
  {
    id:"1",
    title: 'Erstgespräch',
    description: 'Verstehen der Bedürfnisse, Ziele und Vision des Kunden. Besprechung der Zielgruppe, Wettbewerber und Alleinstellungsmerkmale (USPs)'
  },
  {
    id:"2",
    title: 'Recherche & Planung',
    description: `Durchführung von Marktanalysen und Wettbewerbsvergleichen.
Erstellung einer Sitemap und Definition der Website-Struktur.
Planung von Funktionen, Features und Benutzererfahrung (UX).`
  },
  {
    id:"3",
    title: 'Angebot & Vereinbarung',
    description: `Präsentation eines detaillierten Angebots mit Zeitrahmen, Leistungen und Kosten.
Festlegung von Design- und Funktionserwartungen.
Abschluss eines Vertrags oder einer Vereinbarung.
`
  },
  {
    id:"4",
    title: 'Design-Phase',
    description: `Entwicklung von Wireframes oder Mockups für das Layout.
Gestaltung eines ansprechenden und benutzerfreundlichen Designs.
Überarbeitung und Feinabstimmung des Designs basierend auf Kundenfeedback.`
  },
  {
    id:"5",
    title: 'Entwicklungs-Phase',
    description: `Umsetzung des Designs in eine funktionale Website (HTML, CSS, JavaScript etc.).
Implementierung eines CMS (z. B. WordPress) oder maßgeschneiderter Backend-Funktionen.
Hinzufügen von Features wie Formularen, E-Commerce und Integrationen.
`
  },
  {
    id:"6",
    title: 'Content-Erstellung',
    description: `Erstellung oder Sammlung von Inhalten (Texte, Bilder, Videos).
Optimierung der Inhalte für SEO zur Verbesserung der Suchmaschinen-Rankings.
Befüllung der Website mit freigegebenen Inhalten.

`
  },
  {
    id:"7",
    title: 'Testing',
    description: `Testen der Website auf Funktionalität, Responsivität und Browser-Kompatibilität.
Überprüfung auf fehlerhafte Links, Ladegeschwindigkeit und Performance.
Sicherstellen von Sicherheitsfeatures und Backups.

`
  },
  {
    id:"8",
    title: 'Kundenüberprüfung',
    description: `Präsentation der finalen Website an den Kunden zur Freigabe.
Anpassung basierend auf Feedback.
`
  },
  {
    id:"9",
    title: 'Launch',
    description: `Veröffentlichung der Website auf dem Live-Server.
Sicherstellen, dass alles nach dem Launch wie erwartet funktioniert.
Überwachung der Website in den ersten Tagen, um eventuelle Probleme zu beheben.
`
  }
];
const textDetail:TextProps= {
  mainTitle:"Website-Erstellung von A bis Z – Transparent und Verständlich",
  description:"Der typische Prozess der Website Entwicklung umfasst folgende Schritte:",
  headingType:"h2"
}
const getPlanningData = PlanningData.map((data) => {
  return (
    <React.Fragment key={data.id}>
      <li  className="timeline-box wow fadeInLeft animate__animated animate__backInLeft">
        <i className="icon-layers"></i>
        <p className="timeline-title">{data.title}</p>
        <p className="timeline-details">{data.description}</p>
        <span>{data.id}</span>
      </li>
    </React.Fragment>
  );
});
export const WebPlanning: React.FC = () => {
  return (
    <>
      <section className="steps steps-area">
        <div className='short-text_Container'>
      <TextContainer {...textDetail}/>
      </div>
        <div className="container">
          <ul className="timeline">{getPlanningData}</ul>
        </div>
      </section>
    </>
  );
};
