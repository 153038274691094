import React, { useState } from "react";
import { Link } from "react-router-dom";
import "animate.css";

import "./Nav.css";

interface NavBarProps {
  MobileView: boolean;
  isMenuActive: (isMenuActive: boolean) => void;
  isLinkedClicked: boolean;
}

export const Nav: React.FC<NavBarProps> = ({ MobileView, isMenuActive, isLinkedClicked }) => {
  const [isActive, setIsActive] = useState(false);
  const [activeLink, setActiveLink] = useState("Startseite");

  const navItems = [
    { to: "/", title: "Startseite" },
    // Future menu items can be added here
  ];

  const handleMenuToggle = () => {
    setIsActive((prevState) => !prevState);
  };

  const handleLinkClick = (title: string) => {
    setActiveLink(title);
    isMenuActive(true);
    if (!MobileView) {
      setIsActive(false); // Close menu for desktop view
    }
  };

  return (
    <nav className="navbar">
      <div className="logo">
        <img src="./logo1.png" alt="LOGO" />
      </div>
      <div className="push-left">
        <button
          id="menu-toggler"
          aria-label="Toggle menu"
          className={`hamburger ${isActive ? "menu-active" : ""}`}
          onClick={handleMenuToggle}
        >
          <span className="hamburger-line hamburger-line-top"></span>
          <span className="hamburger-line hamburger-line-middle"></span>
          <span className="hamburger-line hamburger-line-bottom"></span>
        </button>
        <ul
          id="primary-menu"
          className={`menu nav-menu ${
            isActive && MobileView
              ? "new__menu animate__animated animate__fadeInRight"
              : ""
          }`}
        >
          {navItems.map((item, index) => (
            <li className="menu-item" key={index}>
              <Link
                className={`nav__link ${
                  isLinkedClicked && activeLink === item.title ? "active" : ""
                }`}
                onClick={() => handleLinkClick(item.title)}
                to={item.to}
              >
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
