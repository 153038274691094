
import { SubServices } from "../SubComponents/SubServices/SubServises";
import "./services.css"
import data from "./codeData.json";
import { JamboImageContainer } from "../SubComponents/JAMBOIMABGE/JamboImageContainer";
interface HomeProps {
  animationVisible: boolean;
}
export const OurServices:React.FC<HomeProps> = (props) => {
  
  const ServicesHeader = {
    title: "Plan/ Design/ Develop/ Online",
    desc: "Discribe your wishes to us for your Business website, get the best services",
    isImageVisibel: false,
    mediaPositionLeft: false,
    isButtonVisible: true,
    buttonText: "OUR PROJECT",
    titleHeading: "h1",
    containerHeight: "50vh",
    backgroundColor: "#FFF9F5",
    jamboImageDisplay: props.animationVisible,
    homeContactAnimationData: data,
    animationLoop: true,
    containerWidth: "100%",
  };
  const subServices = {
    BGColor:"#ffff"
  }
  return (
    <>
      <section>
      <JamboImageContainer
        animationVisible={props.animationVisible}
        {...ServicesHeader}
      />
      </section>
      <section className="services-detail">
      <SubServices backgroundColor = {subServices.BGColor} />
      </section>
      
    </>
  );
};
