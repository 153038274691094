import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Home } from "./COMPONENT/HOME/Home";
import { AboutUs } from "./COMPONENT/ABOUT/AboutUs";
import { OurServices } from "./COMPONENT/SERVICES/Services";
import { Nav } from "./COMPONENT/SubComponents/Nav/Nav";
import { Contact } from "./COMPONENT/CONTACT/Contact";
import { Tools } from "./COMPONENT/TOOLS/Tools";
import { Footer } from "./COMPONENT/SubComponents/FOOTER/Footer";
import homeContact from "./contactus.json";
import { JamboImageContainer } from "./COMPONENT/SubComponents/JAMBOIMABGE/JamboImageContainer";
import { DocumentDesign } from "./COMPONENT/TOOLS/DocumentDesign/EditDocument";

const App = () => {
  const [MobileView, setMobileView] = useState(false);
  const [isActive, setIsActive] = useState(true);

  // Function to handle menu state
  const isMenuActive = (isMenuActive: boolean) => {
    setIsActive(isMenuActive);
  };

  // Function to scroll to the top of the page
  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // HomeContact props
  const HomeContact = {
    title: "Digitales Marketing, SEO & Webentwicklung in Wien",
    desc: "Buhshitech ist Ihre Full-Service-Digitalagentur in Wien. Wir bieten maßgeschneiderte Lösungen für digitales Marketing, SEO, Google Ads, Webentwicklung und Dokumentenmanagement.",
    isImageVisibel: false,
    mediaPositionLeft: false,
    isButtonVisible: true,
    buttonText: "Contact us",
    titleHeading: "h3",
    containerHeight: "80vh",
    jamboImageDisplay: !MobileView, // Derived from MobileView state
    homeContactAnimationData: homeContact,
    colo: "black",
    containerWidth: "90%",
  };

  // Responsive design: Handle screen size changes
  useEffect(() => {
    const matchMedia = window.matchMedia("(max-width: 900px)");

    // Listener for media query changes
    const handleMediaChange = (event: MediaQueryListEvent) => {
      setMobileView(event.matches);
    };

    // Initial check
    setMobileView(matchMedia.matches);

    // Attach the listener
    matchMedia.addEventListener("change", handleMediaChange);

    // Cleanup the listener
    return () => {
      matchMedia.removeEventListener("change", handleMediaChange);
    };
  }, []);

  return (
    <div>
      <Nav MobileView={MobileView} isMenuActive={isMenuActive} isLinkedClicked={isActive} />
      <Routes>
        <Route path="/" element={<Home animationVisible={!MobileView} />} />
        <Route path="/Leistungen" element={<OurServices animationVisible={!MobileView} />} />
        <Route path="/Tools" element={<Tools />} />
        <Route path="/Kontakt" element={<Contact animationVisible={!MobileView} />} />
        <Route
          path="/Tools/DocumentDesign/EditDocument"
          element={<DocumentDesign animationVisible={!MobileView} />}
        />
      </Routes>
      <Footer />
      <button className="goToTop" onClick={goToTop}>
        ↑ Back to Top
      </button>
    </div>
  );
};

export default App;
